.careers-container {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;   
    margin-top: 140px;  
    margin-bottom: 300px;
}

.careers-title-card .careers-title-content {
	padding: 20px;
	text-align: center;
}

.careers-title-card .careers-title-content h2 {
	color: white;
	margin: 20px;
	font-family: 'Inter';
	font-size: 64px;
}

.careers-title-card .careers-title-content p {
	font-size: 1.2em;
	color: white;
	font-weight: 300;
	margin: 20px;
}

.careers-card {
  width: 100%;
}

table {
  width: 95%;
  max-height: 350px;
  overflow: scroll;
  text-align: center;
  justify-content: center;
  margin: 50px auto;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
}

table th {
  font-weight: 700;
  font-size: 20px;
  border-bottom: 1px solid #a88ef6;
  padding: 15px 0px;
}

table td {
  padding: 10px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
}

table td button {
  padding: 8px 20px;
  color: black;
  background-color: #a88ef6;
  border-radius: 10px;
  border: none;
  font-size: 18px;
}

.postingDetails {
  padding: 20px 0px;
  background-color: rgba(255, 255, 255, 0.1);
}

.postingDetails td ul {
  list-style-type: none;
  margin: 15px auto;
}

.postingDetails td button {
  background-color: white;
  color: black;
}
   
@media screen and (max-width: 769px) {
  
  .careers-title-content h2 {
    font-size: 24px; 
    margin: 5px;
  }
}