.industry-container {
	display: flex;
	justify-content: center;
	text-align: center;
	width: 100%;
	margin-top: 175px;
	margin-bottom: 300px;
	background: inherit;
}

.industry-section {
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	margin-bottom: 150px;
}
.industry-section h2 {
	font-family: 'Inter';
	text-align: center;
	margin-bottom: 40px;
}

.industry-card-row {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: stretch;
	margin-bottom: 70px;
}

.industry-card {
	width: 90%;
	text-align: center;
	margin: auto;
	padding: 30px;
	color: white;
	border-radius: 15px;
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.05);
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(5px);
}

.industry-card h3 {
	color: lightgrey;
	margin-bottom: 25px;
}
.industry-card-content > p {
	height: 6rem;
}
.industry-img {
	width: 100%;
	border-radius: 12px;
	height: 215px;
	object-fit: cover;
	padding-bottom: 1rem;
}

.industry-title-card .industry-title-content {
	padding: 20px;
	text-align: center;
}

.industry-title-card .industry-title-content h2 {
	color: white;
	margin: 20px;
	font-family: 'Inter';
	font-size: 64px;
}

.industry-title-card .industry-title-content p {
	font-size: 1.2em;
	color: white;
	font-weight: 300;
	margin: 20px;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
	text-align: center !important;
	font-weight: bold !important;
	color: #ffffff !important;
	font-size: 4em;
}

.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
	color: #a88ef6 !important;
}

.alice-carousel__prev-btn.__inactive,
.alice-carousel__next-btn.__inactive {
	opacity: 0.9 !important;
	pointer-events: none;
}

/*mobile devices*/
@media screen and (min-width: 200px) and (max-width: 480px) {
	.industry-card {
		overflow-wrap: break-word;
		hyphens: auto;
		padding: 2px;
		width: 90%;
		margin: auto auto 2rem auto;
	}

	.industry-card-row {
		flex-direction: column;
		justify-content: center;
		margin: 0px;
	}

	.industry-card p {
		margin: 10px;
	}
	.industry-card-content > p {
		height: 8rem;
	}
}
/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
	.industry-card-content > p {
		height: 9rem;
	}
}
/*laptops, smaller screen computers, ipad air*/
@media screen and (min-width: 769px) and (max-width: 1024px) {
	.industry-card-content > p {
		height: 9rem;
	}
}
