.about-container {
    display: flex;
    width: 100%;   
    margin-top: 150px;  
    margin-bottom: 300px;
}

.about-container .about-title-card {
    flex-direction: column;
}

.about-container .about-title-card .about-title-content {
    width: 85%;
    margin: auto;
    padding: 20px;
	text-align: center;
}

.about-title-card .about-title-content h2 {
    color: white;
	margin: 20px;
	font-family: 'Inter';
	font-size: 64px;
}

.about-title-card .about-title-content p {
    font-size: 1.2em;
	color: white;
	font-weight: 300;
	margin: 20px;
}

.about-section {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
}

.about-section .card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 70px;
    text-align: center;
}

.about-section .about-section-content p {
    font-size: 20px;
    margin: auto 10px;
}

.about-section .about-section-img {
    margin: auto;
}

.about-section .about-section-img img {
    width: 300px;
    border-radius: 50%;
    margin: auto 10px;
}

.about-section .about-section-img-2 img {
    width: 500px;
    border-radius: 15%;
    margin: auto 10px;
}

.about-section .about-section-content {
    width: 50%;
    margin: auto;
}

/*mobile devices*/
@media screen and (min-width: 200px) and (max-width: 480px){
    #about-us-title {
        font-size: 30px;
    }

    .about-container .card .content .about-content {
        font-size: 15px;
    }

    .about-section .about-section-img img {
        width: 180px;
    }

    .about-section .about-section-content p {
        font-size: 15px;
    }

    .about-section .about-section-img-2 img {
        width: 180px;
    }
}
/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
    #about-us-title {
        font-size: 36px;
    }

    .about-container .card .content .about-content {
        font-size: 16px;
    }

    .about-section .about-section-img img {
        width: 225px;
    }

    .about-section .about-section-content p {
        font-size: 16px;
    }

    .about-section .about-section-img-2 img {
        width: 350px;
        border-radius: 15%;
        margin: auto 10px;
    }
}
/*laptops, smaller screen computers, ipad air*/
@media screen and (min-width: 769px) and (max-width: 1024px) {
    #about-us-title {
        font-size: 44px;
    }

    .about-container .card .content .about-content {
        font-size: 18px;
    }

    .about-section .about-section-img img {
        width: 250px;
    }

    .about-section .about-section-content p {
        font-size: 18px;
    }
}