.contact-title h2 {
	color: white;
    margin-top: 150px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-family: 'Inter';
    font-size: 64px;
}

.section {
	min-width: 600px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
	margin-top: 40px;
	z-index: 100;
	background: rgba(255, 255, 255, 0.1);
	box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.25);
	border-right: 1px solid rgba(255, 255, 255, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 10px;
	overflow: hidden;
	backdrop-filter: blur(25px);
	margin-bottom: 350px;
}
.section::before {
	content: ' ';
	position: absolute;
	top: 0;
	left: -45%;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.05);
	pointer-events: none;
	transform: skewX(-15deg);
}

.box {
	width: 100%;
}

.field {
	display: grid;
	grid-template-columns: 0.5fr 1fr;
	grid-gap: 20px;
	grid-auto-rows: minmax(100%, auto-fit);
	position: relative;
	justify-content: center;
	width: 90%;
	color: #fff;
	font-size: 16px;
	margin: auto;
}
.label {
	display: flex;
	align-items: center;
	justify-content: center;
}
.control {
	position: relative;
	width: 100%;
	padding: 0 10px;
	margin: 16px 0 17px;
	height: 50px;
	color: #fff;
	background: transparent;
	box-shadow: none;
	border: none;
	outline: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.control .input,
.control .textarea {
	position: absolute;
	width: 100%;
	height: 100%;
	background: transparent;
	box-shadow: none;
	border: none;
	outline: none;
	font-size: 16px;
	padding: 0 10px;
	z-index: 99;
	color: #fff;
}
.control .textarea {
	resize: none;
	padding: 0 10px;
	top: 0;
	left: 0;
	height: 100%;
	line-height: 40px;
	font-size: 18px;
	display: block;
}

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90%;
	height: 50px;
	margin: 20px auto;
	color: #fff;
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.25);
	outline: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 5px;
	cursor: pointer;
	z-index: 99;
	font: inherit;
}

.contact-container {
	position: absolute;
	z-index: -99;
	width: 100%;
	height: 100%;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.contact-container img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	filter: blur(10px);
	z-index: -99;
}

/* .hero {
	position: absolute;
	left: calc(100% - 60%);
	top: 150px;
	font-family: 'Inter';
	font-size: 32px;
} */

@media screen and (max-width: 290px) {
	.section {
		min-width: 270px;
		height: fit-content;
	}
	.contact-title h2 {
        font-size: 35px;
    }
}

/*mobile devices*/
@media screen and (min-width: 291px) and (max-width: 480px) {
	.section {
		min-width: 280px;
		height: fit-content;
	}
	.contact-title h2 {
        font-size: 35px;
    }
}
/*iphone xr, iphone 12pro*/
/* @media screen and (min-width: 390px) {
	.section {
		min-width: 380px;
		height: fit-content;
	}
} */
/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
	.section {
		min-width: 450px;
		height: fit-content;
	}
	.contact-title h2 {
        font-size: 40px;
    }
}
/*laptops, smaller screen computers, ipad air*/
@media screen and (min-width: 769px) and (max-width: 1024px) {
	.section {
		height: fit-content;
	}
	.contact-title h2 {
        font-size: 50px;
    }
}
/*larger screen computers*/
@media screen and (min-width: 1025px) and (max-width: 1280px) {
	.section {
		height: fit-content;
	}
}

@media screen and (max-width: 1380px) {
	.contact-title h2 {
		margin-top: 180px;
	}
}
