.current-invoices {
    display: flex;
    min-height: 80%;
    width: -webkit-fill-available;
  }
  
  .current-invoices-container {
    background-color: white;
    color: black;
    width: 95%;
    height: 80%;
    margin: 125px auto 25px auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
  }
  
  .current-invoices-card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: rgb(212, 212, 212);
    color: black;
    min-width: 95%;
    min-height: 95%;
    height: auto;  
    padding: 30px 10px;
    border-radius: 10px 10px 10px 10px;
  }  

  .current-invoices-card:not(:first-child) {
    margin-top: 2em;
  }

  .current-invoice-list-card {
    display: flex; 
    justify-content: center;      
  }
  
  .current-invoice-list-section {
    display: flex;
    width: 100%;
    justify-content: center;  
  }

  .current-invoices-list {
    width: 100%;
  }
  
  .current-invoices-list-item {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 1rem 0;  
    padding: .3rem;
    border-bottom: 2px solid #ccc;
    list-style: none;
  }

  .request-list-section {
    display: flex;
    width: 50%;
    justify-content: center;  
  }
  
  .current-invoices-card h2 {
    color: black;
    font-size: 48px;
    text-align: center;  
    margin: 5px auto 30px auto;
    width: 100%;
    padding: 0;
  }
  
  .current-invoices-card h3 {
    color: black;
    font-size: 18px;
    text-align: center;
    margin: auto;  
    padding: 0;
  }
  
  .current-invoices-card h4 {  
    color: rgb(48, 46, 46);
    font-size: 14px;
    text-align: center;  
    margin: auto;
  }

  .current-invoice-divider-box {
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  
  .current-invoice-divider {
    flex-direction: row;
    width: 75%;
    display: flex;
  }
  
  .current-invoice-title-divider {
    width: 100%;
    display: flex;
  }

  @media screen and (max-width: 390px) {
    .current-invoice-list-item {
      flex-direction: column;
      align-items: center;
    }
  
    .current-invoice-title-divider {
      flex-direction: column;
      width: 90%;
    }
  
    .current-invoice-list-section {
      width: 100%;
      margin: 5px auto;
    }
  
    .current-invoice-list-divider {
      flex-direction: column;
    }
  
    .current-invoice-divider {
      flex-direction: column;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      margin: 5px;
    }
  
    .current-invoice-divider-box {
      flex-direction: column;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 5px;
    }
  
    .current-invoices-card h2 {
      font-size: 38px;
      margin: 5px auto;
    }
  }
  
  /*mobile devices*/
  @media screen and (min-width: 391px) and (max-width: 480px) {
    .current-invoice-list-item {
      flex-direction: column;
      align-items: center;
    }
  
    .current-invoice-title-divider {
      flex-direction: column;
      width: 90%;
    }
  
    .current-invoice-list-section {
      width: 100%;
      margin: 5px auto;
    }
  
    .current-invoice-list-divider {
      flex-direction: column;
    }
  
    .current-invoice-divider {
      flex-direction: column;
      width: 48%;
      display: flex;
      justify-content: space-evenly;
      margin: 0 5px;
    }
  
    .current-invoice-divider-box {
      flex-direction: row;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      margin-top: 5px;
    }
  
    .current-invoices-card h2 {
      font-size: 38px;
      margin: 5px auto;
    }
  }
  
  /*ipads and tablets*/
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .current-invoice-list-item {
      flex-direction: column;
      align-items: center;
    }
  
    .current-invoice-title-divider {
      flex-direction: column;
      width: 90%;
    }
  
    .current-invoice-list-section {
      width: 100%;
      margin: 5px auto;
    }
  
    .current-invoice-list-divider {
      flex-direction: column;
    }
  
    .current-invoice-divider {
      flex-direction: column;
      width: 48%;
      display: flex;
      justify-content: space-evenly;
      margin: 0 5px;
    }
  
    .current-invoice-divider-box {
      flex-direction: row;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      margin-top: 5px;
    }
  
    .current-invoices-card h2 {
      font-size: 42px;
      margin: 5px auto;
    }
  }