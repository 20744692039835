.employees {
  display: flex;
  min-height: 80%;
  width: -webkit-fill-available;
}

.employees-container {
  background-color: white;
  color: black;
  width: 95%;
  height: 80%;
  margin: 125px auto 25px auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
}

.employees-card {
  background-color: rgb(212, 212, 212);
  color: black;
  min-width: 95%;
  height: 95%;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.employees-card-header {
 display: flex;
 flex-direction: column;
 margin-bottom: 1rem;
}

.employee-card-footer {
  display: flex;
  justify-content: right;
  margin: 2rem;
}

.list-card {
  display: flex; 
  justify-content: center;  
}

.employees-list {
  width: 100%;  
}

.employee-list-item {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 1rem 0;  
  padding: .3rem;
  border-bottom: 2px solid #ccc;
  list-style: none;
}

/*if we make the items clickable */
/*.employee-list-item:hover {
  background-color: rgba(179, 171, 171, 0.534);
  cursor: pointer; 
}*/

.pl-item {
  padding:.3em;
}

.employee-list-section {
  display: flex;
  width: 100%;
  justify-content: center;  
  padding: 0 5px;
}

/* .create-employee-btn {
  margin: auto 5.5rem auto auto;
  border: none;
  background-color: #a88ef6;
  font-weight: 700;
  padding: 10px 25px;
  border-radius: 25px; 
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);   
} */

.create-employee-button {  
  margin-top: 40px;  
  padding: 15px 20px;
  border-radius: 50px;
  font-size: 24px;
  font-weight: 700;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: #a88ef6;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);
}

.employees-card h2 {
  color: black;
  font-size: 48px;
  text-align: center;  
  margin: 5px auto 40px auto;
  width: 100%;
  padding: 0;
}

.employees-card h3 {
  color: black;
  font-size: 18px;
  text-align: center;
  margin: auto;  
  padding: 0;
}

.employees-card h4 {  
  color: rgb(48, 46, 46);
  font-size: 14px;
  text-align: center;  
  margin: auto;
  overflow-wrap: break-word;
}

.employee-divider-box {
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.employee-divider {
  flex-direction: row;
  width: 75%;
  display: flex;
}

.employee-project-divider {
  flex-direction: row;
  width: 50%;
  display: flex;
}

.employee-title-divider {
  width: 25%;
  display: flex;
}

@media screen and (max-width: 390px) {
  .employee-list-item {
    flex-direction: column;
    align-items: center;
  }

  .employee-project-divider {
    width: 100%;
  }

  .employee-list-section {
    width: 100%;
    margin: 5px auto;
  }

  .employee-list-divider {
    flex-direction: column;
  }

  .employee-divider {
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 5px;
  }

  .employee-divider-box {
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 5px;
  }

  .employees-card h2 {
    font-size: 38px;
    margin: 5px auto;
  }

  .employees-card {
    padding: 15px;
  }
}

/*mobile devices*/
@media screen and (min-width: 391px) and (max-width: 480px) {
  .employee-list-item {
    flex-direction: column;
    align-items: center;
  }

  .employee-project-divider {
    width: 48%;
  }

  .employee-list-section {
    width: 100%;
    margin: 5px auto;
  }

  .employee-list-divider {
    flex-direction: column;
  }

  .employee-divider {
    flex-direction: column;
    width: 48%;
    display: flex;
    justify-content: space-evenly;
    margin: 0 5px;
  }

  .employee-divider-box {
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
  }

  .employees-card h2 {
    font-size: 38px;
    margin: 5px auto;
  }

  .employees-card {
    padding: 15px;
  }
}

/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
  .employee-list-item {
    flex-direction: column;
    align-items: center;
  }

  .employee-project-divider {
    width: 48%;
  }

  .employee-list-section {
    width: 100%;
    margin: 5px auto;
  }

  .employee-list-divider {
    flex-direction: column;
  }

  .employee-divider {
    flex-direction: column;
    width: 48%;
    display: flex;
    justify-content: space-evenly;
    margin: 0 5px;
  }

  .employee-divider-box {
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
  }

  .employees-card h2 {
    font-size: 42px;
    margin: 5px auto;
  }
}