footer {
	position: absolute;
	bottom: 0;
	left: 0;
	justify-content: center;
	align-items: center;
	margin: 10px auto 0 auto;
	z-index: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
}

footer .footer-logo {
	display: flex;
	text-align: center;
	align-items: center;
	margin-right: 65px;
	flex-direction: row;
}

.footer-logo a {
	display: flex;
	align-items: center;
	width: 100%;
}

footer .footer-logo img {
	width: 100px;
	margin: auto;
	display: flex;
	margin-right: 15px;
}

.footer-logo a p {
	font-size: 22px;
	display: flex;
	width: min-content;
}

.footer-logo .social-links {
	display: inline-block;
	width: 100%;
}

.social-links {
	margin: auto;
}

.social-links li {
	list-style-type: none;
	margin: 10px;
	width: 30%;
	display: inline-block;
}

.social-links li a img {
	width: 35px;
}

footer .footer-links {
	margin: auto 30px;
	display: flex;
	text-align: center;
}

.footer-links .nav-links {
	width: 100%;
	display: flex;
	text-align: center;
	flex-direction: column;
}

.footer-links .nav-links .title {
	font-weight: 700;
	text-decoration: underline;
	padding: 10px;
	margin: 0;
}

.footer-links ul li {
	list-style-type: none;
	margin: 5px 0;
	width: 100%;
	justify-content: center;
	display: flex;
}

.footer-links ul li a {
	text-decoration: none;
	padding: 5px 20px;
	color: white;
	justify-content: center;
	display: flex;
}

.footer-links ul li a img {
	width: 40px;
}

.footer-copyright p {
	color: white;
	margin: 3px 0 10px 0;
	text-align: center;
	font-size: 0.8em;
}

.footer-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.footer-copyright {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-links-container {
    display: flex;
    flex-direction: row;
    /* width: 80%; */
    justify-content: flex-start;
}

/*mobile phones*/
@media screen and (max-width: 600px) {
	footer {
		position: absolute;
		bottom: 0;
		left: 0;
		justify-content: center;
		align-items: center;
		margin: 10px auto 0 auto;
		z-index: 1;
		width: 100%;
		display: flex;
		flex-direction: column;
		text-align: center;
	}

	/* footer .footer-logo {
		width: 100%;
		display: inline-block;
		text-align: center;
		vertical-align: bottom;
		margin: auto;
	}

	footer .footer-logo img {
		width: 100px;
		margin: auto;
	}

	.footer-logo .social-links {
		display: inline-block;
		width: 50%;
	}

	.social-links li {
		list-style-type: none;
		margin: 10px 0;
		width: 30%;
		display: inline-block;
	} */

	/* .social-links li a img {
		width: 35px;
	} */

	footer .footer-links {
		margin: 0;
	}
/*
	.footer-links .nav-links {
		width: 100%;
		display: inline-block;
		text-align: center;
		vertical-align: middle;
	} */

	#services-footer-links {
		display: none;
	}

	footer .footer-logo a img {
		width: 80px;
	}

	.footer-logo a p {
		font-size: 18px;
	}
}
