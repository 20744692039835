.employee-container {
    left: 18em;
}


/*laptops, smaller screen computers, ipad air*/
@media screen and (max-width: 1024px) {
	.employee-container {
    left: 0;
  }
}