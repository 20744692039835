@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&family=Inter&family=Roboto+Mono&family=Russo+One&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	max-width: 100%;
}

body {
	width: 100vw;
	justify-content: center;
	align-items: center;
	background: #0b011bf5;
	font-family: 'Anonymous Pro', monospace;
	font-size: 18px;
	margin: auto;
	color: white;
}

.app-container {
	position: relative;
	min-height: 135vh;
	margin: auto;
	display: flex;
	justify-content: center;
	background: linear-gradient(#48248c, #0b011bf5);
	overflow-x: hidden;
}

.container {
	position: absolute;
	top: 160px;
	width: 70%;
	flex-wrap: wrap;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	border-radius: 25px;
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.05);
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(5px);
}

.container h1 {
	color: white;
	margin: 20px;
}

.container .card {
	width: 100%;
	margin: 15px auto;
	display: flex;
	z-index: 1;
}

.card .content {
	padding: 20px;
	text-align: center;
}

.card .content h2 {
	color: white;
	margin: 20px;
	font-family: 'Inter';
	font-size: 64px;
}

.card .content p {
	font-size: 1.2em;
	color: white;
	font-weight: 300;
	margin: 20px;
}

.card .content a {
	position: relative;
	display: inline-block;
	width: 250px;
	padding: 20px 20px;
	margin: 20px 40px;
	color: white;
	border-radius: 20px;
	text-decoration: none;
	border-radius: 15px;
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.05);
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	font-family: 'Inter', sans-serif;
	font-weight: 700;
	font-size: 28px;
}

.card .content a:hover {
	color: #142357;
	background: #fff;
}
