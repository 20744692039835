.job-openings {
  display: flex;
  min-height: 80%;
  width: -webkit-fill-available;
}

.job-openings-container {
  background-color: white;
  color: black;
  width: 95%;
  height: 80%;
  margin: 125px auto 25px auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
}

.job-openings-card {
  background-color: rgb(212, 212, 212);
  color: black;
  min-width: 95%;
  height: 95%;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.job-openings-card h2 {
  color: black;
  text-align: center;
  margin: 5px auto 40px auto;
  width: 100%;
  padding: 0;
  font-size: 48px;
}

.job-openings-card .job-content {
  margin: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.job-openings-card .job-content ul {
  list-style-type: none;
  margin: auto;
  flex-direction: row;
  display: flex;
  width: 100%;
}

/* .job-openings-card .job-content ul li {
  margin: auto;
  display: flex;
  padding: 0 5px;
  color: black;
  vertical-align: middle;
  font-size: 16px;
} */

.job-position {
  font-weight: 700;
  margin: auto;
  margin-left: 0;
  display: flex;
  padding: 5px;
  color: black;
  vertical-align: middle;
  font-size: 16px;
}

.job-desc {
  margin: auto;
  display: flex;
  padding: 5px;
  color: black;
  vertical-align: middle;
  font-size: 16px;
}

.job-delete {
  width: 50px;
  margin: 0;
  margin-right: 5px;
  display: flex;
  padding: 5px;
  color: black;
  vertical-align: middle;
  font-size: 16px;
  justify-content: center;
}

.job-expand {
  width: 50px;
  margin: 0;
  margin-right: 5px;
  display: flex;
  padding: 5px;
  color: black;
  vertical-align: middle;
  font-size: 16px;
  justify-content: center;
}

.job-resp {
  margin: auto;
  display: flex;
  padding: 5px;
  color: black;
  vertical-align: middle;
  font-size: 16px;
}

.job-qual {
  margin: auto;
  display: flex;
  padding: 5px;
  color: black;
  vertical-align: middle;
  font-size: 16px;
}

.mobile-desc {
  display: none;
  margin: auto;
  padding: 5px;
  color: black;
  vertical-align: middle;
  font-size: 16px;
}

.job-delete button, .job-expand button {
  background-color: inherit;
  border: none;
}

.material-icons {
  font-size: 26px;
  color: #48248c;
  margin: 0;
}

.jobs-expanded-content {
  margin-bottom: 15px;
  margin: auto;
  width: 100%;
  text-align: center;
}

.job-openings-card .jobs-expanded-content ul {
  list-style-type: none;
  margin: 0 auto 30px auto;
  flex-direction: column;
  display: flex;
  width: 90%;
}

/* .job-openings-card .jobs-expanded-content ul li {
  margin: auto;
  display: flex;
  padding: 5px;
  color: black;
  vertical-align: middle;
  font-size: 16px;
} */

.addJobBtn {
  align-self: flex-end;
  margin-top: 40px;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #a88ef6;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4); 
}

.create-job-form {
  width: 100%;
  margin: auto 0;
  text-align: center;
}

.create-job-form div {
  width: 40%;
  display: inline-block;
  margin: auto 10px;
}

.create-job-form label {
  display: block;
  margin-bottom: 15px;
}

.create-job-form textarea {
  border: 1px solid #48248c;
  width: 250px;
  height: 50px;
  margin-bottom: 50px;
  padding: 4px 10px;
  border-radius: 5px;
}

.create-job-form button {
  margin: auto;
  width: 40%;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  background-color: #a88ef6;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4); 
  font-size: 20px;
}

@media screen and (max-width: 390px) {
  .create-job-form div {
    width: 100%;
  }

  .create-job-form button {
    width: 80%;
  }

  .job-openings-card h2 {
    font-size: 38px;
    margin: 5px auto 40px auto;
  }

  .job-desc {
    display: none;
  }

  .mobile-desc {
    display: flex;
  }

  .job-position {
    width: fit-content;
  }
}

/*mobile devices*/
@media screen and (min-width: 391px) and (max-width: 480px) {
  .job-openings-card h2 {
    font-size: 38px;
    margin: 5px auto 40px auto;
  }

  .job-desc {
    display: none;
  }

  .mobile-desc {
    display: flex;
  }

  .job-position {
    width: fit-content;
  }
}

/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
  .job-openings-card h2 {
    font-size: 42px;
    margin: 5px auto 40px auto;
  }

  .job-openings-card div ul {
    flex-direction: column;
  }

  .job-desc {
    display: none;
  }

  .mobile-desc {
    display: flex;
  }

  .job-position {
    width: fit-content;
  }
}

/*ipads and tablets*/
@media screen and (min-width: 391px) and (max-width: 600px) {
  .create-job-form div {
    width: 100%;
  }
}