.sidenav {
	position: fixed;
	/* z-index: 1; */
	background: #56578c;
	width: 320px;
	height: 100vh;
	left: 0;
	top: 0;
	background-color: white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	overflow-y: scroll;
}
.sidenav-menu {
	position: relative;
	top: 98px;
	height: inherit;
	width: 100%;
}

.sidenav-menu-item,
.sidenav-menu-item-active {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.75rem 2rem;
	text-decoration: none;
	background-color: #9674d5a0;
	color: #555;
	margin: 2rem auto 0 auto;
	border-radius: 10%;
	overflow-x: hidden;
	font-size: 1.75rem;
	font-weight: 500;
	width: calc(100% - 23px);
}

.sidenav-menu-item-active {
	border-radius: 10% 10% 0 0;
}
.sidenav-menu-item:hover,
.sidenav-menu-item-active:hover {
	background-color: #48248c;
	color: #fff;
	cursor: pointer;
	width: calc(100% - 23px);
	border-radius: 10px;
	transform: translateX(1%);
	transition: 0.3s ease-in-out;
}
.sidenav-menu-item-active:hover {
	border-radius: 10% 10% 0 0;
	transform: translateX(0.3%);
}

.sidenav-menu-item:hover .sidenav-menu-item-text,
.sidenav-menu-item:hover .sidenav-menu-item-icon,
.sidenav-menu-item:hover .sidenav-menu-settings-icon {
	color: #fff;
}

.sidenav-menu-item-text {
	margin-left: 2rem;
}

.sidenav-menu-item-icon {
	display: flex;
	margin-right: -1rem;
}
a {
	text-decoration: none;
	color: unset;
}
li#sidenav-menu-settings {
	place-content: center;
}
.sidenav-menu-settings-icon {
	bottom: 0;
	transform: scale(2);
}

.dash-footer-logo {
	width: 100%;
	display: inline-block;
	text-align: center;
	vertical-align: bottom;
	margin: auto;
	margin-top: 50px;
}

.dash-footer-logo img {
	width: 100px;
	margin: auto;
}

.dash-footer-logo p {
	color: #48248c;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 22px;
	font-size: 14px;
}
/*mobile devices*/
@media screen and (min-width: 240px) and (max-width: 480px) {
	.sidenav {
		left: -100%;
		transition: 350ms;
		width: 100%;
	}
	.sidenav.active {
		left: 0;
		transition: 350ms;
	}
	.sidenav-bar {
		position: absolute;
		top: 15%;
		left: -31px;
		display: flex;
		justify-content: start;
		align-items: center;
	}
	.sidenav-toggle {
		list-style-type: none;
		background-color: #3f1f7b21;
		width: 100%;
		height: 38px;
		display: flex;
		justify-content: start;
		align-items: center;
	}
	.sidenav-menu-bars {
		margin-left: 2rem;
		background: #a88ef6;
		border-radius: 5px;
		align-items: center;
		display: flex;
		list-style-type: none;
	}
}
/*iphone xr*/
@media screen and (min-width: 390px) {
	.sidenav-bar {
		top: 12%;
	}
}
/*iphone 12, pixel 5*/
@media screen and (min-width: 414px) {
	.sidenav-bar {
		top: 11%;
	}
}
/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
	.sidenav {
		left: -100%;
		transition: 350ms;
		width: 100%;
	}
	.sidenav.active {
		left: 0;
		transition: 350ms;
	}
	.sidenav-bar {
		position: absolute;
		top: 15%;
		left: -31px;
		display: flex;
		justify-content: start;
		align-items: center;
	}

	.sidenav-toggle {
		list-style-type: none;
		background-color: #3f1f7b21;
		width: 100%;
		height: 38px;
		display: flex;
		justify-content: start;
		align-items: center;
	}
	.sidenav-menu-bars {
		margin-left: 2rem;
		background: #a88ef6;
		border-radius: 5px;
		align-items: center;
		display: flex;
		list-style-type: none;
	}
}
/*laptops, smaller screen computers, ipad air*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
	.sidenav {
		left: -100%;
		transition: 350ms;
		width: 100%;
	}
	.sidenav.active {
		left: 0;
		transition: 350ms;
	}
	.sidenav-bar {
		position: absolute;
		top: 100px;
		left: -31px;
		display: flex;
		justify-content: start;
		align-items: center;
	}

	.sidenav-toggle {
		list-style-type: none;
		background-color: #3f1f7b21;
		width: 100%;
		height: 38px;
		display: flex;
		justify-content: start;
		align-items: center;
	}
	.sidenav-menu-bars {
		margin-left: 2rem;
		background: #a88ef6;
		border-radius: 5px;
		align-items: center;
		display: flex;
		list-style-type: none;
	}
}
/*larger screen computers*/
@media screen and (min-width: 1025px) and (max-width: 1200px) {
}
