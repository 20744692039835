.home-container {
	position: relative;
	width: 100%;
	/* height: 200vh; */
	margin: auto;
}

.home-container .home-card-container {
	height: 100vh;
	display: flex;
	width: 100%;
	position: relative;
	margin: auto;
	justify-content: center;
	align-items: center;
	background: linear-gradient(#48248c, #0b011bf5);
}

.home-container .home-card-container .card {
	width: 50%;
	float: left;
	display: flex;
	z-index: 1;
	margin: auto auto auto 40px;
}

.home-section {
	position: relative;
	width: 100%;
	padding: 0 100px;
	margin: 0px auto;
	margin-bottom: 300px;
	display: flex;
	flex-direction: row;
	/* height: 100vh; */
	background: rgb(18, 20, 34);
	justify-content: space-evenly;
}

.home-section .home-card {
	width: 30%;
	margin: 150px 10px 150px 10px;
	text-align: center;
	/* height: max-content; */
	/* min-height: 90%; */
	flex-grow: 1;
}

.home-card:hover {
	transform: scale(1.08);
}

/* .home-section .home-card img {
	width: 50px;
	margin: auto auto 20px auto;
} */

.home-card .home-card-content {
	width: 100%;
	padding: 20px;
	color: white;
	border-radius: 15px;
	background: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.05);
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(5px);
	font-size: 16px;
	align-items: stretch;
}

.home-card .home-card-content h3 {
	margin-bottom: 20px;
	font-weight: 700;
	text-decoration: underline;
	color: white;
}

.home-card .home-card-content h3:hover {
	color: #a88ef6;
}

.landing-img {
	width: 100%;
	border-radius: 12px;
	height: 214px;
	object-fit: cover;
	padding-bottom: 1rem;
}

.home-card-text.truncate-overflow {
	height: 13rem;
}

.truncate-overflow p {
	/* --lh: 1.2rem;
	line-height: var(--lh);
	--max-lines: 9;
	max-height: calc(var(--lh) * var(--max-lines));
	overflow: hidden; */
	overflow: hidden;
	max-width: inherit;
	/* -webkit-line-clamp: 17;
	-webkit-box-orient: vertical;
	display: -webkit-box; */
	height: 13rem;
}

.home-card-container .animated-logo {
	margin: 15px auto;
	display: flex;
	width: 62em;
	height: 86em;
}

.home-card-container .animated-logo img {
	width: 400px;
	margin: auto;
	animation: animate 5s linear infinite;
}

.material-icons {
	font-size: 60px;
	color: #48248c;
	margin-bottom: 20px;
}

@keyframes animate {
	0%,
	100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.3);
	}
}

@media screen and (max-width: 360px) {
	.home-card-text.truncate-overflow {
		height: 15rem;
	}

	.truncate-overflow p {
		height: 15rem;
	}
}

/*mobile devices */
@media screen and (min-width: 280px) and (max-width: 480px) {
	/*320px min width before*/
	.home-section {
		flex-direction: column;
		padding: 40px 5px !important;
		width: 100%;
	}
	.home-card-container {
		flex-direction: column;
		height: 100% !important;
		z-index: 0;
	}
	.home-section .home-card {
		margin: 10px auto !important;
		width: 90%;
	}
	.card {
		margin-top: 11em !important;
		width: -webkit-fill-available !important;
		float: none !important;
		margin-left: 0 !important;
	}
	.content h2 {
		font-size: 40px !important;
	}
	.animated-logo > div > div {
		width: 100% !important;
	}
	.home-card-container .animated-logo {
		/* height: 371.516px; */
		height: 20em;
	}
}

/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
	.home-section {
		padding: 40px 5px !important;
		width: 100%;
		flex-direction: column;
	}

	.home-card-container {
		flex-direction: column;
		height: 100% !important;
		z-index: 0;
	}

	.content h2 {
		font-size: 55px !important;
	}

	.home-section .home-card {
		margin: 10px auto !important;
		width: 90%;
	}
	.card {
		margin-top: 11em !important;
		width: -webkit-fill-available !important;
		float: none !important;
		margin-left: 0 !important;
	}
	.animated-logo > div > div {
		width: 100% !important;
	}

	.home-card-container .animated-logo {
		/* height: 371.516px; */
		height: 36em;
	}
}

/*laptops, smaller screen computers, ipad air*/
@media screen and (min-width: 769px) and (max-width: 1024px) {
	.home-card {
		margin: 0 10px !important;
	}
	.home-section {
		padding: 75px 5px !important;
	}

	.home-card-container {
		flex-direction: column;
		height: 100% !important;
		z-index: 0;
	}

	.animated-logo > div > div {
		width: 100% !important;
	}

	.card {
		margin-top: 11em !important;
		width: -webkit-fill-available !important;
		float: none !important;
		margin-left: 0 !important;
	}
	.home-card-container {
		z-index: 0;
	}

	.home-card-container .animated-logo {
		/* height: 371.516px; */
		height: 40em;
	}
	.home-card-text.truncate-overflow {
		height: 20rem;
	}

	.truncate-overflow p {
		height: 20rem;
	}
}

/*larger screen computers*/
@media screen and (min-width: 1025px) and (max-width: 1200px) {
	.card {
		margin-top: 11em !important;
		width: -webkit-fill-available !important;
		float: none !important;
		margin-left: 0 !important;
	}

	.home-card-container {
		flex-direction: column;
		height: 100% !important;
		z-index: 0;
	}

	.home-card-container {
		z-index: 0;
	}

	.home-card-container .animated-logo {
		/* height: 371.516px; */
		height: 50em;
	}

	.animated-logo > div > div {
		width: 100% !important;
	}

	.animated-logo > div > div canvas {
		width: 900px !important;
	}
}
