.edit-site {
  display: flex;
  min-height: 80%;
  width: -webkit-fill-available;
}
 
.edit-site-container {
  background-color: white;
  color: black;
  width: 95%;
  height: 80%;
  margin: 125px auto 25px auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
}

.edit-site-card {
  background-color: rgb(212, 212, 212);
  color: black;
  min-width: 95%;
  height: 95%;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.edit-site-card h2 {
  color: black;
  font-size: 48px;
  text-align: center;
  margin: 5px auto 50px auto;
  width: 100%;
  padding: 0;
}

/* .edit-site-card table {
  border: 1px solid rgb(173, 173, 173);
  background-color: white;
  width: 95%;
  min-height: 85%;
  margin: auto;
  border-radius: 5px;
}

.table-headers:not(:last-child) td {
  border-bottom: 1px solid rgb(173, 173, 173);
}

.edit-site-card table button {
  background: none;
  border: none;
  font-size: 24px;
}

.siteContent td {
  background-color: #baa7f690;
}

.siteContent:not(:last-child) td {
  border-bottom: 1px solid rgb(173, 173, 173);
} */

.edit-site-card div {
  margin: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  display: flex;
}

.edit-site-card .table-headers ul {
  list-style-type: none;
  margin: auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.edit-site-card .table-headers ul li {
  margin: 0;
  display: flex;
  padding: 0 8px;
  color: black;
  vertical-align: middle;
}

.header-title {
  width: auto;
  text-align: start;
  font-size: 24px;
  font-weight: 700;
}

.header-button button {
  width: 100%;
  background-color: inherit;
  border: none;
}

.material-icons {
  font-size: 26px;
  color: #48248c;
  margin: 0;
}

.siteForm form {
  width: 100%;
}

.siteForm .input-group {
  width: 40%;
  display: inline-block;
  margin: 10px auto;
  text-align: center;
}

.siteForm .input-group span {
  display: block;
}

.siteForm .input-group input {
  text-align: center;
  width: 90%;
  padding: 4px 10px;
  border-radius: 5px;
  border: 1px solid #48248c;
}

.siteForm .input-group textarea {
  display: block;
  margin: 5px auto;
  text-align: center;
  width: 90%;
  padding: 4px 10px;
  border-radius: 5px;
  border: 1px solid #48248c;
}

.siteContent {
  width: 100%;
  margin: auto;
  text-align: center;
  display: flex;
}

.siteContent ul {
  list-style-type: none;
  margin: auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.siteContent ul li {
  margin: auto;
  display: flex;
  padding: 0 8px;
  color: black;
  vertical-align: middle;
  width: 90%;
}

.siteContent form button {
  display: block;
  margin: 10px auto;
  width: 20%;
  height: 30px;
  border-radius: 15px;
  border: 1px solid black;
}

@media screen and (max-width: 390px) {
  .edit-site-card h2 {
    font-size: 38px;
  }

  .header-title {
    font-size: 20px;
  }

  .siteForm .input-group {
    width: 100%;
  }

  .siteContent form button {
    width: 75px;
  }
}

/*mobile devices*/
@media screen and (min-width: 391px) and (max-width: 480px) {
  .edit-site-card h2 {
    font-size: 38px;
  }

  .header-title {
    font-size: 22px;
  }

  .siteForm .input-group {
    width: 100%;
  }
}

/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
  .edit-site-card h2 {
    font-size: 42px;
  }

  .siteForm .input-group {
    width: 90%;
  }
}