.projects {
    display: flex;
    min-height: 80%;
    width: -webkit-fill-available;
  }
  
  .projects-container {
    background-color: white;
    color: black;
    width: 95%;
    height: 80%;
    margin: 125px auto 25px auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
  }
  
  .projects-card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: rgb(212, 212, 212);
    color: black;
    min-width: 95%;
    min-height: 95%;
    height: auto;  
    padding: 30px;
    border-radius: 10px 10px 10px 10px;
  }

  .projects-card:not(:first-child) {
    margin-top: 2em;
  }

  .list-card {
    display: flex; 
    justify-content: center;      
  }
  
  .list-section {
    display: flex;
    width: 20%;
    justify-content: center;  
  }

  .projects-list {
    width: 100%;
  }
  
  .project-list-item {
    display: flex;
    justify-content: left;
    flex-direction: row;
    margin: 2rem;  
    padding: .3rem;
    border-bottom: 2px solid #ccc;
    list-style: none;
  }
  
  .projects-card h2 {
    color: black;
    font-size: 48px;
    text-align: center;  
    margin: 5px auto 40px auto;
    width: 100%;
    padding: 0;
  }
  
  .projects-card h3 {
    color: black;
    font-size: 18px;
    text-align: center;
    margin: auto;  
    padding: 0;
  }
  
  .projects-card h4 {  
    color: rgb(48, 46, 46);
    font-size: 12px;
    text-align: center;  
    margin: auto;
  }