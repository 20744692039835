.dash-navbar {
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    z-index: 1;
    background-color: white;
  }
  
  .dash-navbar .dash-header-brand {
    width: 280px;
    display: flex;
  }
  
  .dash-navbar .dash-header-brand a {
    color: #a88ef6;
    text-decoration: none;
    font-size: 24px;
    font-weight: 700;
    font-family: "Inter";
    display: flex;
    margin: auto 5px;
  }
  
  .dash-navbar .dash-header-brand a img {
    width: 90px;
  }

  .dash-navbar h1 {
    color: rgb(89, 89, 89);
    margin: auto;
    font-family: 'Inter';
  }

  .dash-navbar .dash-header-right {
    width: 280px;
    display: flex;
    flex-flow: row-reverse;
  }

  .dash-header-right button {
    margin: auto 0 auto auto;
    border: none;
    background-color: #a88ef6;
    font-weight: 700;
    padding: 15px 35px;
    border-radius: 25px;
  }

  /*ipads and tablets*/
@media screen and (max-width: 768px) {
  .dash-navbar .dash-header-brand a {
    font-size: 20px;
    margin: auto 3px;
  }

  .dash-navbar .dash-header-brand a img {
    width: 65px;
  }

  .dash-navbar {
    padding: 15px 10px;
  }

  .dash-navbar h1 {
    display: none;
  }

  .dash-navbar-right {
    width: 165px;
  }
}