.home-container-admin {
  display: flex;  
  min-height: 80%;
  width: -webkit-fill-available;
}

 .home-dash-container {
  background-color: white;
  color: black;
  width: 95%;
  min-height: 80%; 
  height: auto; 
  display: flex;
  flex-direction: column;   
  padding: 20px;
  margin: 125px auto 25px auto;
  border-radius: 10px 10px 10px 10px;
}

.home-card-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;    
  margin-top: 2em;
}

.home-dash-card {
  display: flex;
  flex-direction: column;
  background-color: rgb(212, 212, 212);
  color: black;
  min-width: 95%;
  height: 95%;
  height: auto;  
  padding: 30px;
  border-radius: 10px 10px 10px 10px;
}



.half-card {
  display: flex;
  flex-direction: column;  
  background-color: rgb(212, 212, 212);
  color: black;
  width: 48%;
  min-height: 95%;
  height: auto;  
  padding: 10px;
  border-radius: 10px 10px 10px 10px;
}

.home-dash-card:not(:first-child) {
  margin-top: 2em;
}


.list-card {
  display: flex; 
  justify-content: center;  
  
}

.home-list-section {
  display: flex;
  width: 100%;
  justify-content: center;  
}

.request-list-section {
  display: flex;
  width: 50%;
  justify-content: center;  
}

.request-list-section.one {  
  justify-content: flex-start; 
  margin-left: 5px; 
}
.request-list-section.two {  
  justify-content: flex-end;  
}

.home-list {
  width: 100%;
}

.home-list-item, .request-list-item {
  display: flex;
  justify-content: left;
  flex-direction: row;
  margin: 2rem 0;  
  padding: .3rem;
  border-bottom: 2px solid #ccc;
  list-style: none;
}

.requests-icon-group {
 margin: auto; 
}

.request-btn {
  background-color: inherit;
  border: none;
  padding-right: 10px;
}

.home-dash-card h2 {
  color: black;
  font-size: 48px;
  text-align: center;  
  margin: 5px auto 40px auto;
  width: 100%;
  padding: 0;
}

.half-card h2 {
  color: black;
  font-size: 36px;
  text-align: center;  
  margin: 5px auto 5px auto;
  width: 100%;
  padding: 0;
}

.home-dash-card h3 {
  color: black;
  font-size: 18px;
  text-align: center;
  margin: auto;  
  padding: 0;
}

.half-card h3 {
  color: black;
  font-size: 18px;
  text-align: center;
  margin: auto 0;  
  padding: 0;
}

.material-icons.request {
  font-size: 26px;
  color: #a88ef6;
  padding: 0.1em;
}

.material-icons.request:hover {  
  color:#48248c; 
}

.home-dash-card h4 {  
  color: rgb(48, 46, 46);
  font-size: 12px;
  text-align: center;  
  margin: auto;
} 

.half-card h4 {  
  color: rgb(155, 6, 6);
  font-size: 19px;
  text-align: center;    
} 

.home-list-divider {
  flex-direction: row;
  width: 48%;
  display: flex;
}

.home-divider-box {
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.home-list-title {
  width: 25%;
}

@media screen and (max-width: 390px) {
  .home-dash-card h2 {
    font-size: 38px;
    margin: 5px auto;
  }

  .home-list-item {
    flex-direction: column;
    align-items: center;
  }

  .home-list-divider {
    flex-direction: column;
  }

  .home-list-section {
    width: 100%;
    padding: 5px 0;
  }

  .home-divider-box {
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
  }
  
  .half-card h2 {
    font-size: 20px;
  } 

  .home-list-title {
    width: 100%;
  }
}

/*mobile devices*/
@media screen and (min-width: 391px) and (max-width: 480px) {
  .home-dash-card h2 {
    font-size: 38px;
    margin: 5px auto;
  }

  .home-list-item {
    flex-direction: column;
    align-items: center;
  }

  .home-list-divider {
    flex-direction: column;
  }

  .home-list-section {
    width: 100%;
    padding: 5px 0;
  }

  .home-divider-box {
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
  }

  .half-card h2 {
    font-size: 26px;
  }

  .home-list-title {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .request-list-section.two {
    justify-content: space-evenly;
    width: 100%;
  }

  .request-list-item {
    flex-direction: column;
    align-items: center;
  }

  .request-list-section.one {
    margin-left: 0;
    justify-content: center;
    width: 100%;
  }

  .request-list-section.one h3 {
    font-size: 16px;
  }
}

/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
  .home-dash-card h2 {
    font-size: 42px;
    margin: 5px auto;
  }

  .half-card h2 {
    font-size: 32px;
  }
}

/*laptops, smaller screen computers, ipad air*/
@media screen and (min-width: 769px) and (max-width: 1024px) {
  
}

/*larger screen computers*/
@media screen and (min-width: 1390px) {
  
}