.card {
    flex-direction: column;

}
.services-container {
   display: flex;
    width: 100%;   
    margin-top: 175px;  
    margin-bottom: 300px;
  }
  /* used a large margin here to space this section from the nav and footer.*/
  /* we need to set app css to flex-direction: row;  */

.services-section{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
}

.services-card-row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    margin-bottom: 2rem;
}

.services-card{    
    width: 40%;
    text-align: center;       
    padding: 30px;
    color: white;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, .05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);   
}

.services-card h3 {
    color: lightgrey;
    margin-bottom: 25px;
}

.services-card p {    
    line-height: 1.5em;
    margin: 20px;
}

.material-icons .services-icon { 
    font-size: 80px;
    color: white;
    margin-bottom: 20px;    
  }

  .services-img {
    width: 100%;
    border-radius: 12px;
    height: 214px;
    object-fit: cover;
    padding-bottom: 1rem;
  }

  .services-title-card .services-title-content {
	padding: 20px;
	text-align: center;
}

.services-title-card .services-title-content h2 {
	color: white;
	margin: 20px;
	font-family: 'Inter';
	font-size: 64px;
}

.services-title-card .services-title-content p {
	font-size: 1.2em;
	color: white;
	font-weight: 300;
	margin: 20px;
}

/*mobile devices*/
@media screen and (min-width: 200px) and (max-width: 480px){
    .services-card {
        overflow-wrap: break-word;
        hyphens: auto;
        padding: 2px;
        width: 90%;
        margin: auto auto 2rem auto;
        
    }

    .services-card-row {
        flex-direction: column;
        justify-content:center;
        margin: 0px;
    }     
    
    .services-card p {           
        margin: 10px;
    }

}
/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
    .services-card {
        overflow-wrap: break-word;
        hyphens: auto;
        padding: 5px;
        width: 45%;
        margin-bottom: 1.5rem; 
    }

    .services-card-row {               
        margin: 0px;
    }
    
    .services-card p {           
        margin: 10px;
    }
}
/*laptops, smaller screen computers, ipad air*/
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .services-card {
        overflow-wrap: break-word;
        hyphens: auto;
        padding: 15px;
        width: 45%;
        margin-bottom: 1.5rem; 
    }
}
/*larger screen computers*/
@media screen and (min-width: 1390px) {
    .content {
        width: 80%;
        margin: auto;
    }
}

