.job-apply-form {
    width: 80%;
    margin: auto;
    text-align: center;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 20px;
  }
  
  .job-apply-form div {
    width: 40%;
    display: inline-block;
    margin: 20px;
  }
  
  .job-apply-form label {
    display: block;
    margin-bottom: 15px;
  }

  .job-apply-form input {
    height: 30px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 200px;
  }
  
  .job-apply-form button {
    margin: 40px 10px auto 10px;
    width: 20%;
    height: 50px;
    border-radius: 25px;
    text-align: center;
    background-color: #a88ef6;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4); 
    font-size: 20px;
  }

  /*mobile devices*/
@media screen and (min-width: 200px) and (max-width: 480px){
  .job-app-title {
      font-size: 30px;
  }

  .job-apply-form button {
    width: 75%;
  }
}
/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
  .job-app-title {
      font-size: 36px;
  }
  .job-apply-form button {
    width: 75%;
  }
}

/*laptops, smaller screen computers, ipad air*/
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .job-app-title {
      font-size: 44px;
  }
}

@media screen and (max-width: 1390px) {
  .job-app-title {
      margin-top: 180px;
  }
}

@media screen and (max-width: 690px) {
  .job-apply-form div {
      width: 95%;
  }
}