.clients {
  display: flex;
  min-height: 80%;
  width: -webkit-fill-available;
}

.clients-container {
  background-color: white;
  color: black;
  width: 95%;
  height: 80%;
  margin: 125px auto 25px auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
}

.clients-card {
  background-color: rgb(212, 212, 212);
  color: black;
  min-width: 95%;
  height: 95%;
  padding: 30px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.clients-card-header {
 display: flex;
 flex-direction: column;
 margin-bottom: 1rem;
}


.clients-list-card {
  display: flex; 
  justify-content: center;  
  
}

.clients-list {
  width: 100%;
}

.clients-list-item {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 1rem 0;  
  padding: .3rem;
  border-bottom: 2px solid #ccc;
  list-style: none;
}

/*if we make the items clickable */
/*.clients-list-item:hover {
  background-color: rgba(179, 171, 171, 0.534);
  cursor: pointer; 
}*/

.pl-item {
  padding:.3em;
}

.client-list-section {
  display: flex;
  width: 100%;
  justify-content: center;  
  padding: 0 5px;
}

.clients-card h2 {
  color: black;
  font-size: 48px;
  text-align: center;  
  margin: 5px auto;
  width: 100%;
  padding: 0;
}

.clients-card h3 {
  color: black;
  font-size: 18px;
  text-align: center;
  margin: auto;  
  padding: 0;
}

.clients-card h4 {  
  color: rgb(48, 46, 46);
  font-size: 14px;
  text-align: center;  
  margin: auto;
  overflow-wrap: break-word;
}

.client-divider-box {
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.client-divider {
  flex-direction: row;
  width: 75%;
  display: flex;
}

.client-project-divider {
  flex-direction: row;
  width: 25%;
  display: flex;
}

.client-title-divider {
  width: 25%;
  display: flex;
}

@media screen and (max-width: 390px) {
  .clients-list-item {
    flex-direction: column;
    align-items: center;
  }

  .client-project-divider {
    width: 48%;
  }

  .client-list-section {
    width: 100%;
    margin: 5px auto;
  }

  .client-list-divider {
    flex-direction: column;
  }

  .client-divider {
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 5px;
  }

  .client-divider-box {
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 5px;
  }

  .clients-card h2 {
    font-size: 38px;
    margin: 5px auto;
  }
}

/*mobile devices*/
@media screen and (min-width: 391px) and (max-width: 480px) {
  .clients-list-item {
    flex-direction: column;
    align-items: center;
  }

  .client-project-divider {
    width: 48%;
  }

  .client-list-section {
    width: 100%;
    margin: 5px auto;
  }

  .client-list-divider {
    flex-direction: column;
  }

  .client-divider {
    flex-direction: column;
    width: 48%;
    display: flex;
    justify-content: space-evenly;
    margin: 0 5px;
  }

  .client-divider-box {
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
  }

  .clients-card h2 {
    font-size: 38px;
    margin: 5px auto;
  }
}

/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
  .clients-list-item {
    flex-direction: column;
    align-items: center;
  }

  .client-project-divider {
    width: 48%;
  }

  .client-list-section {
    width: 100%;
    margin: 5px auto;
  }

  .client-list-divider {
    flex-direction: column;
  }

  .client-divider {
    flex-direction: column;
    width: 48%;
    display: flex;
    justify-content: space-evenly;
    margin: 0 5px;
  }

  .client-divider-box {
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
  }

  .clients-card h2 {
    font-size: 42px;
    margin: 5px auto;
  }
}