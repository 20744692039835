.navbar {
	position: absolute;
	width: 100%;
	/* height: 100px; */
	top: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 30px;
	z-index: 100;
}

.navbar .navbar-brand {
	width: 280px;
	display: flex;
}

.material-icons.navbar-icon {
	font-size: 30px;
	margin: 0;
}

.navbar .navbar-brand a {
	color: white;
	text-decoration: none;
	font-size: 24px;
	font-weight: 700;
	font-family: 'Inter';
	display: flex;
	margin: auto 5px;
}

.navbar .navbar-brand a img {
	width: 90px;
}

.navbar .navbar-pages {
	width: calc(100% - 295px);
	display: flex;
	margin: auto 0;
	font-family: 'Anonymous Pro', monospace;
	font-size: 18px;
}

.navbar-pages ul {
	display: flex;
	width: 100%;
	justify-content: flex-end;
}

.navbar-pages ul li {
	list-style-type: none;
	margin: 0;
	display: flex;
	text-align: center;
	align-items: center;
}

.navbar-pages ul li a {
	color: white;
	text-decoration: none;
	padding: 15px 15px;
	margin: 0 10px;
}

.navbar-pages ul li a:hover {
	color: white;
	border-bottom: 2px solid #a88ef6;
}

.navbar-pages ul li .loginBtn {
	color: white;
	font-weight: 700;
	text-decoration: none;
	padding: 15px 35px;
	border-radius: 25px;
	background: rgba(255, 255, 255, 0.15);
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);
}

.navbar-pages ul li .loginBtn:hover {
	color: #142357;
	background: rgba(255, 255, 255, 0.9);
}

.small-screen {
	display: none;
}

@media screen and (min-width: 1001px) and (max-width: 1265px) {
	.navbar-divider li a {
		font-size: 16px;
	}

	.navbar .navbar-brand a {
		font-size: 20px;
	}

	.navbar .navbar-brand a img {
		width: 60px;
	}

	.navbar-brand {
		width: 250px;
	}

	.navbar {
		padding: 15px;
	}
}

@media screen and (min-width: 856px) and (max-width: 1001px) {
	.navbar-divider li a {
		font-size: 13px;
	}

	.navbar .navbar-brand a {
		font-size: 16px;
	}

	.navbar .navbar-brand a img {
		width: 40px;
	}

	.navbar .navbar-brand {
		width: 230px;
	}

	.navbar {
		padding: 10px;
	}

	.navbar-pages ul li .loginBtn {
		padding: 15px 20px;
	}
}

@media screen and (min-width: 790px) and (max-width: 855px) {
	.navbar-divider li a {
		font-size: 11px;
	}

	.navbar .navbar-brand a {
		font-size: 14px;
	}

	.navbar .navbar-brand a img {
		width: 40px;
	}

	.navbar .navbar-brand {
		width: 200px;
	}

	.navbar {
		padding: 10px;
	}

	.navbar-pages ul li .loginBtn {
		padding: 15px 20px;
	}
}

@media screen and (min-width: 550px) and (max-width: 789px) {
	.navbar-pages ul {
		flex-direction: column;
    	align-items: center;
	}

	.navbar-pages ul li {
		margin: 0;
		justify-content: center;
	}

	li.navbar-divider {
		flex-direction: row;
		width: 100%;
	}

	.navbar-divider li a {
		font-size: 13px;
	}

	.navbar .navbar-brand a {
		font-size: 16px;
	}

	.navbar .navbar-brand a img {
		width: 50px;
	}

	.navbar .navbar-brand {
		width: 250px;
	}

	.navbar {
		padding: 10px;
	}

	.navbar .navbar-pages {
		width: calc(100vw - 250px);
	}
}

@media screen and (max-width: 549px) {
	.large-screen {
		display: none;
	}

	.small-screen {
		display: inline-block;
	}

	.navbar {
		position: absolute;
		width: 100%;
		min-height: 150px;
		top: 0;
		left: 0;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		padding: 15px 30px;
		z-index: 100;
		overflow: visible;
	}

	.navbar .navbar-brand {
		width: 100%;
		display: flex;
		margin: auto;
		justify-content: center;
		margin-bottom: 10px;
	}

	.navbar .navbar-brand a {
		color: white;
		text-decoration: none;
		font-size: 28px;
		font-weight: 700;
		font-family: 'Inter';
		display: flex;
		margin: auto 5px;
	}

	.navbar .navbar-pages {
		width: 100%;
		display: flex;
		margin: auto;
		justify-content: center;
	}

	.navbar-pages button {
		padding: 10px 20px;
		border-radius: 25px;
		background: #a88ef6;
		border: none;
		margin-right: 0;
	}

	.navbar-pages button:hover {
		cursor: pointer;
	}

	.dropdown-menu {
		position: absolute;
		margin: 50px 0;
		padding: 10px 0;
		border: 1px solid grey;
		background: white;
		color: black;
		width: 100%;
		border-radius: 25px;
		text-align: center;
		display: flex;
		flex-direction: column;
	}

	.dropdown-menu ul {
		list-style-type: none;
		display: contents;
	}

	.dropdown-menu ul li {
		margin: 10px auto;
		padding: 10px 0;
	}

	.dropdown-menu ul li a {
		text-decoration: none;
		padding: 2px;
		color: #142357;
	}

	.dropdown-menu ul li a:hover {
		border-bottom: 2px solid #a88ef6;
		color: #142357;
	}

	.dropdown-menu ul li .loginBtn {
		padding: 15px 35px;
		border-radius: 25px;
		background: #a88ef6;
		border: none;
	}

	.dropdown-menu ul li .loginBtn:hover {
		color: #142357;
	}
}

/* @media screen and (min-width: 510px) and (max-width: 905px) {
	.navbar .navbar-brand a img {
		width: 60px;
	}
} */

@media screen and (max-width: 510px) {
	.navbar .navbar-brand a img {
		width: 75px;
	}
}

/*mobile devices */
/* @media screen and (min-width: 280px) and (max-width: 480px) {
	.navbar-pages ul {
		flex-direction: column;
		z-index: 11;
	}
} */
/*ipads and tablets*/
/* @media screen and (min-width: 481px) and (max-width: 1200px) {
	.navbar-pages ul {
		list-style-type: none;
		display: inline-block;
	}
} */