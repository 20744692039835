.settings {
  display: flex;
  min-height: 80%;
  width: -webkit-fill-available;
} 

.settings-container {
  background-color: white;
  color: black;
  width: 95%;
  height: 80%;
  margin: 125px auto 25px auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
}

.settings-card {
  background-color: rgb(212, 212, 212);
  color: black;
  min-width: 95%;
  height: 95%;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.settings-card h2 {
  color: black;
  text-align: center;
  margin: 5px auto 40px auto;
  width: 100%;
  padding: 0;
  font-size: 48px;
}

.settings-card div {
  margin: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.change-pswd-form {
  width: 75%;
  margin: auto;
  text-align: center;
}

.change-pswd-form div {
  width: 40%;
  display: inline-block;
  margin: auto 20px;
}

.change-pswd-form label {
  display: block;
  margin-bottom: 15px;
}

.change-pswd-form input {
  border: 1px solid #48248c;
  width: 250px;
  height: 40px;
  margin-bottom: 30px;
  padding: 4px 10px;
  border-radius: 5px;
}

.change-pswd-form button {
  margin: auto;
  width: 40%;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  background-color: #a88ef6;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4); 
  font-size: 20px;
}

.drNucU > span {
  color: black;
}
