.client-sidenav {
	position: fixed;
	/* z-index: 1; */
	background: #56578c;
	width: 320px;
	height: 100vh;
	left: 0;
	top: 0;
	background-color: white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	overflow-y: scroll;
}

.client-sidenav-menu {
	position: relative;
	top: 98px;
	height: inherit;
	width: 100%;
}
.client-sidenav-menu-item,
.client-sidenav-menu-item-active {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.75rem 2rem;
	text-decoration: none;
	background-color: #9674d5a0;
	color: #555;
	margin: 2rem auto 0 auto;
	border-radius: 10%;
	overflow-x: hidden;
	font-size: 1.75rem;
	font-weight: 500;
	width: calc(100% - 23px);
}
.client-sidenav-menu-item-active {
	border-radius: 10% 10% 0 0;
}
.client-sidenav-menu-item:hover,
.client-sidenav-menu-item-active:hover {
	background-color: #48248c;
	color: #fff;
	cursor: pointer;
	width: calc(100% - 23px);
	border-radius: 10px;
	transform: translateX(1%);
	transition: 0.3s ease-in-out;
}
.client-sidenav-menu-item-active:hover {
	border-radius: 10% 10% 0 0;
	transform: translateX(0.3%);
}

/* .client-sidenav-menu-item {
	display: flex;
	align-items: center;
	place-content: flex-start;
	padding: 3.75rem 3rem;
	margin: 3rem 0 0 0;
	background-color: #9674d5a0;
	border-radius: 10%;
	overflow-x: hidden;
	font-size: 1.75rem;
	font-weight: 500;
	box-shadow: #c2b4dca0 10px;
	color: #555;
}

.client-sidenav-menu-item:hover {
	width: calc(100% - 23px);
	border-radius: 10px;
	background-color: #48248c;
	transform: translateX(3%);
	transition: 0.3s ease-in-out;
	cursor: pointer;
} */
.client-sidenav-menu-item:hover .client-sidenav-menu-item-text,
.client-sidenav-menu-item:hover .client-sidenav-menu-item-icon,
.client-sidenav-menu-item:hover .client-sidenav-menu-settings-icon {
	color: #fff;
}

/* .client-sidenav-menu-item-children {
	height: 60px;
	padding-left: 9em;
	display: flex;
	align-items: center;
	text-align: right;
	text-decoration: none;
	font-size: 8px;
	list-style-type: none;
	width: 94%;
	transform: translate(20px, 0px);
	border-radius: 1px 0 0 10px;
	background-color: #9f87cb;
}
.client-sidenav-menu-item-children:hover {
	background-color: #6b49a9;
	cursor: pointer;
} */
/* .client-sidenav-menu-item-children-text {
	color: #fff;
	font-size: 1.5rem;
} */
.client-sidenav-menu-item-text {
	margin-left: 2rem;
}
.client-sidenav-menu-item-icon {
	display: flex;
	margin-right: -1rem;
}

a {
	text-decoration: none;
	color: unset;
}

/* .client-sidenav-menu-item-text {
	list-style-type: none;
	color: #555;
	margin-left: 1.5rem;
	font-size: 1.5rem;
	font-weight: 500;
	transition: color 0.3s ease-in-out;
}

.client-sidenav-menu-item-icon {
	margin-right: 1rem;
	transform: scale(1.5);
	color: #555;
	font-size: 1.75rem;
	transition: color 0.3s ease-in-out;
} */
li#client-sidenav-menu-settings {
	place-content: center;
}
.client-sidenav-menu-settings-icon {
	bottom: 0;
	transform: scale(2);
}

.dash-footer-logo {
	width: 100%;
	display: inline-block;
	text-align: center;
	vertical-align: bottom;
	margin: auto;
	margin-top: 50px;
}

.dash-footer-logo img {
	width: 100px;
	margin: auto;
}

.dash-footer-logo p {
	color: #48248c;
	width: 100%;
	margin-top: 5px;
	margin-bottom: 22px;
	font-size: 14px;
}
/*mobile devices*/
@media screen and (min-width: 240px) and (max-width: 480px) {
	.client-sidenav {
		left: -100%;
		transition: 350ms;
		width: 100%;
	}
	.client-sidenav.active {
		left: 0;
		transition: 350ms;
	}
	.client-sidenav-bar {
		position: absolute;
		top: 15%;
		left: -31px;
		display: flex;
		justify-content: start;
		align-items: center;
	}
	.client-sidenav-toggle {
		list-style-type: none;
		background-color: #3f1f7b21;
		width: 100%;
		height: 38px;
		display: flex;
		justify-content: start;
		align-items: center;
	}
	.client-sidenav-menu-bars {
		margin-left: 2rem;
		background: #a88ef6;
		border-radius: 5px;
		align-items: center;
		display: flex;
		list-style-type: none;
	}
}
/*iphone xr*/
@media screen and (min-width: 390px) {
	.client-sidenav-bar {
		top: 12%;
	}
}
/*iphone 12, pixel 5*/
@media screen and (min-width: 414px) {
	.client-sidenav-bar {
		top: 11%;
	}
}
/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
	.client-sidenav {
		left: -100%;
		transition: 350ms;
		width: 100%;
	}
	.client-sidenav.active {
		left: 0;
		transition: 350ms;
	}
	.client-sidenav-bar {
		position: absolute;
		top: 15%;
		left: -31px;
		display: flex;
		justify-content: start;
		align-items: center;
	}

	.client-sidenav-toggle {
		list-style-type: none;
		background-color: #3f1f7b21;
		width: 100%;
		height: 38px;
		display: flex;
		justify-content: start;
		align-items: center;
	}
	.client-sidenav-menu-bars {
		margin-left: 2rem;
		background: #a88ef6;
		border-radius: 5px;
		align-items: center;
		display: flex;
		list-style-type: none;
	}
}
/*laptops, smaller screen computers, ipad air*/
@media screen and (min-width: 768px) and (max-width: 1024px) {
	.client-sidenav {
		left: -100%;
		transition: 350ms;
		width: 100%;
	}
	.client-sidenav.active {
		left: 0;
		transition: 350ms;
	}
	.client-sidenav-bar {
		position: absolute;
		top: 100px;
		left: -31px;
		display: flex;
		justify-content: start;
		align-items: center;
	}

	.client-sidenav-toggle {
		list-style-type: none;
		background-color: #3f1f7b21;
		width: 100%;
		height: 38px;
		display: flex;
		justify-content: start;
		align-items: center;
	}
	.client-sidenav-menu-bars {
		margin-left: 2rem;
		background: #a88ef6;
		border-radius: 5px;
		align-items: center;
		display: flex;
		list-style-type: none;
	}
}
/*larger screen computers*/
@media screen and (min-width: 1025px) and (max-width: 1200px) {
}
