.create-invoice {
    display: flex;
    min-height: 80%;
    width: -webkit-fill-available;
  }
  
  .create-invoice-container {
    background-color: white;
    color: black;
    width: 95%;
    height: 80%;
    margin: 125px auto 25px auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
  }
  
  .create-invoice-card {
    background-color: rgb(212, 212, 212);
    color: black;
    min-width: 95%;
    height: 95%;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .create-invoice-card-header {
    display: flex;
    flex-direction: row;
  }
  
  .invoice-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
  }
  
  .create-invoice-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
  }
  
  .invoice-form-group {
    display: flex;
    flex-direction: row;
    justify-content: center; 
    width: 100%; 
  } 
  
  .invoice-form-element {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 1.5rem;    
  }

  #invoiceAmount {
    text-align: center;
    font-weight: bold;
    padding-right: 5px;
  }
  
  .invoice-form-element-selector{
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 1.5rem;
  }
  
  
  .invoice-formControl {
    justify-content: left;
    flex-direction: row;
    width: 100%; 
    height:2rem; 
    background: transparent;
    border: none; 
    border-bottom: 2px solid rgb(151, 149, 149);
  }
  
  .invoice-formControlArea {  
    font-size: 14px; 
  }
  
  .in-label {  
    font-size: 18px;
    color: rgb(85, 83, 83);
    padding: 0.5em 0; 
  }

  .in-label.amount {
    text-align: center;
    padding-bottom: 1.5em;
  }
  
  .in-label.selector {  
    width: 60%; 
  }
  
  .invoice-list-card {
    display: flex; 
    justify-content: center;    
  }
  
  select.invoice-drp {
    width: 10rem; 
  }
  
  .create-invoice-card h2 {
    color: black;
    font-size: 48px;
    text-align: center;  
    margin: 5px auto 30px auto;
    width: 100%;
    padding: 0;
  }
  
  .create-invoice-card h3 {
    color: black;
    font-size: 18px;
    text-align: center;
    margin: auto;  
    padding: 0;
  }
  
  .create-invoice-card h4 {  
    color: rgb(48, 46, 46);
    font-size: 14px;
    text-align: center;  
    margin: auto;
  }
  
  .dashboard-btn {
    color: black;
    font-size: 18px;
    font-family: Anonymous Pro;  
    font-weight: 700;
    text-decoration: none;
    padding: 10px 25px;
    border-radius: 25px;
    background: #a88ef6;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);
    margin:  30px 20px;
    border: none;  
    cursor: pointer;
  } 

  @media screen and (max-width: 390px) {
    .create-invoice-card h2 {
      font-size: 38px;
      margin: 5px auto;
    }
  
    .invoice-form-element {
      align-items: center;
      width: 100%;
    }
  
    .invoice-form-group {
      flex-direction: column;
      text-align: center;
      align-items: center;
    }

    .invoice-form-element-selector {
      align-items: center;
    }
  }
  
  /*mobile devices*/
  @media screen and (min-width: 391px) and (max-width: 480px) {
    .create-invoice-card h2 {
      font-size: 38px;
      margin: 5px auto;
    }
  
    .invoice-form-element {
      align-items: center;
      width: 100%;
    }
  
    .invoice-form-group {
      flex-direction: column;
      text-align: center;
      align-items: center;
    }

    .invoice-form-element-selector {
      align-items: center;
    }
  }
  
  /*ipads and tablets*/
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .create-invoice-card h2 {
      font-size: 42px;
      margin: 5px auto;
    }
  }
  