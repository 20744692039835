/* .admin-container {
  position: absolute;
  top: 215px;
  width: 40%;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  overflow: hidden;
} */

.admin-container {
  margin-left: 320px !important;
}

/*laptops, smaller screen computers, ipad air*/
@media screen and (max-width: 1024px) {
	.admin-container {
    margin-left: 0px !important;
  }
}