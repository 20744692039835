.create-employee {
  display: flex;
  min-height: 80%;
  width: -webkit-fill-available;
}

.create-employee-container {
  background-color: white;
  color: black;
  width: 95%;
  height: 80%;
  margin: 125px auto 25px auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
}

.create-employee-card {
  background-color: rgb(212, 212, 212);
  color: black;
  min-width: 95%;
  height: 95%;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.create-employee-card-header {
  display: flex;
  flex-direction: row;
}

.employee-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.create-employee-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.employee-form-group {
  display: flex;
  flex-direction: row;
  justify-content: center;  
}


.employee-form-element {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 1.5rem;
}

.formControl {
  justify-content: left;
  flex-direction: row;
  width: 100%; 
  height:2rem; 
  background: transparent;
  border: none; 
  border-bottom: 2px solid rgb(151, 149, 149);
}

.em-label {  
  font-size: 18px;
  color: rgb(85, 83, 83);
  padding: 0.5em 0;
 
}

.list-card {
  display: flex; 
  justify-content: center;    
}

select.project-drp {
  width: 12rem; 
}

.create-employee-card h2 {
  color: black;
  font-size: 48px;
  text-align: center;  
  margin: 5px auto 40px auto;
  width: 100%;
  padding: 0;
}

.create-employee-card h3 {
  color: black;
  font-size: 18px;
  text-align: center;
  margin: auto;  
  padding: 0;
}

.create-employee-card h4 {  
  color: rgb(48, 46, 46);
  font-size: 14px;
  text-align: center;  
  margin: auto;
}



.dashboard-btn {
  color: black;
  font-size: 18px;
  font-family: Anonymous Pro;  
  font-weight: 700;
  text-decoration: none;
  padding: 10px 25px;
  border-radius: 25px;
  background: #a88ef6;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);
  margin:  30px 20px;
  border: none;  
  cursor: pointer;
} 

@media screen and (max-width: 390px) {
  .create-employee-card h2 {
    font-size: 38px;
    margin: 5px auto;
  }

  .employee-form-element {
    align-items: center;
    width: 100%;
  }

  .employee-form-group {
    flex-direction: column;
    text-align: center;
  }
}

/*mobile devices*/
@media screen and (min-width: 391px) and (max-width: 480px) {
  .create-employee-card h2 {
    font-size: 38px;
    margin: 5px auto;
  }

  .employee-form-element {
    align-items: center;
    width: 100%;
  }

  .employee-form-group {
    flex-direction: column;
    text-align: center;
  }
}

/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
  .create-employee-card h2 {
    font-size: 42px;
    margin: 5px auto;
  }


}