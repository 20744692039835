.login h2 {
    color: white;
    margin-top: 150px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-family: 'Inter';
    font-size: 64px;
}

.login-container {
    width: 600px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, .05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    overflow: hidden;
    margin-top: 40px;
    margin-bottom: 400px;
}

.login-container::before {
    content: " ";
    position: absolute;
    top: 0;
    left: -45%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.05);
    pointer-events: none;
    transform: skewX(-15deg);
}

.login-container .login-form {
    text-align: center;
    width: 100%;
    padding: 50px;
    font-size: 20px;
}

.login-form div {
    padding-bottom: 20px;
}

.login-form .login-username, .login-form .login-pswd {
    padding-bottom: 40px;
}

.login-form div input {
    width: 200px;
    height: 35px;
    background: transparent;
    box-shadow: none;
    border: none;
    outline: none;
    font-size: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    color: white;
    text-align: center;
}

.login-form button {
    height: 50px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.25);
    outline: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);
    color: white;
    font-size: 18px;
    width: 100%;
    font-family: 'Anonymous Pro';
}

/*mobile devices*/
@media screen and (min-width: 200px) and (max-width: 480px){
    .login h2 {
        font-size: 30px;
    }

    .login-container {
        width: 320px;
    }
}
/*ipads and tablets*/
@media screen and (min-width: 481px) and (max-width: 768px) {
    .login h2 {
        font-size: 36px;
    }

    .login-container {
        width: 450px;
    }
}
/*laptops, smaller screen computers, ipad air*/
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .login h2 {
        font-size: 44px;
    }

    .login-container {
        width: 550px;
    }
}

@media screen and (max-width: 1390px) {
    .login h2 {
        margin-top: 180px;
    }
}