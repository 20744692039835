.client-sidenav-menu-item-children {
	height: 60px;
	padding-left: 2.5em;
	display: flex;
	align-items: center;
	font-size: 20px;
	text-decoration: none;
	background-color: #9f87cb;
	width: calc(100% - 24px);
	margin-left: 12px;
}

.client-sidenav-menu-item-children:nth-of-type(3) {
	border-radius: 0 0 10px 10px;
}

.client-sidenav-menu-item-children-text {
	color: #fff;
	margin-left: 15px;
}

.client-sidenav-menu-item-children:hover {
	background-color: #6b49a9;
	cursor: pointer;
}

/* .sidenav-menu-item-children-li:hover {
	font-weight: bold;
	cursor: pointer;
} */